import React from "react";
import {graphql} from "gatsby";

import Layout from "../../components/layout";
import PostLink from "../../components/post_link";
import PostList from "../../components/post_list";

export default ({data}) => (
  <Layout dir="rtl">
    <h2>Posts</h2>
    {console.log(data)}
    <PostList>
      {data.allMarkdownRemark.edges.map(({node})=>(
        <PostLink link={"/fa/blog/"+node.frontmatter.path} date={node.frontmatter.date}>
          {node.frontmatter.title}
        </PostLink>
      ))}
    </PostList>
  </Layout>
);

export const query = graphql`
    {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/blog_fa/"}}, sort: {order: DESC, fields: frontmatter___date}) {
        edges {
          node {
            frontmatter {
              path
              title
              date(formatString: "MMMM Do, Y")
            }
          }
        }
      }
    }
`;
